var $log = function (msg) {
        console.log(msg);
    },
    App = {
        init: function () {
            //this.backTop();

            this.ajax_page();
            this.scripts();
            this.popups();
        },

        ajax_page: function () {
            var body = $('.page-wrap');

            body.one('transitionend webkitTransitionEnd oTransitionEnd', function () {
                App.sliders();
            }).removeClass('fade-hidden');

            $("a[href^='" + base_path + "']").each(function (i, o) {
                var $self = $(this),
                    href = $self.attr('href'),
                    href_pure = href.replace(base_path, '');

                if (href_pure.indexOf('.') === -1) {
                    $self.on('click', function (event) {
                        event.preventDefault();
                        /*
						 $.ajax({
						 xhr: function () {
						 var xhr = new window.XMLHttpRequest();

						 xhr.upload.addEventListener("progress", function (evt) {
						 if (evt.lengthComputable) {
						 var percentComplete = evt.loaded / evt.total;
						 //Do something with upload progress
						 $log(percentComplete);
						 }
						 }, false);
						 //Download progress
						 xhr.addEventListener("progress", function (evt) {
						 if (evt.lengthComputable) {
						 var percentComplete = evt.loaded / evt.total;
						 //Do something with download progress
						 $log(percentComplete);

						 }
						 }, false);
						 return xhr;
						 },
						 type: 'GET',
						 url: href,
						 data: {},
						 success: function (data) {
						 $log(data);
						 //window.location.href = href;
						 },
						 error: function (data) {
						 $log(data);
						 //window.location.href = href;
						 }
						 });*/

                        //$.ajax({
                        //	url: href,
                        //	xhrFields: {
                        //		onprogress: function (e) {
                        //			if (e.lengthComputable) {
                        //				console.log(e.loaded / e.total * 100 + '%');
                        //			}
                        //		}
                        //	},
                        //	success: function (response) {
                        //		$log(response)
                        //	}
                        //});

                        var loader_target = $('.push-menu-wrap .loading-bar');

                        $.ajax({
                            type: 'GET',
                            url: href,
                            cache: true,
                            error: function (xhr, ajaxOptions, thrownError) {
                                window.location.href = href;
                            },
                            xhr: function () {
                                var xhr = new window.XMLHttpRequest();
                                //Download progress
                                xhr.addEventListener(
                                    'progress',
                                    function (evt) {
                                        if (evt.lengthComputable) {
                                            var percentComplete = evt.loaded / evt.total;
                                            loader_target.css('width', Math.round(percentComplete * 100) + '%');
                                        }
                                    },
                                    false
                                );
                                return xhr;
                            },
                            beforeSend: function () {
                                loader_target.css('width', '25%');
                            },
                            complete: function () {
                                loader_target.css('width', '100%');
                            },
                            success: function (json) {
                                window.location.href = href;
                            }
                        });

                        /*$.get(href, function (response) {

						 body.one("transitionend webkitTransitionEnd oTransitionEnd", function () {

						 window.location.href = href;

						 }).addClass("fade-hidden");


						 }).fail(function () {
						 window.location.href = href;
						 });*/
                    });
                }
            });
        },

        scripts: function () {
            // ### Content menu ###
            $('.content-menyy a').click(function (event) {
                event.preventDefault();
                var id = $(this).attr('data-slug');
                $('html,body').animate(
                    {
                        scrollTop: $('#' + id).offset().top - 100
                    },
                    1000
                );
            });

            // ### Wrap tables ###
            $('.main-content table').wrap("<div class='table-wrap'></div>");

            // Home hero image
            var imgWrap = $('.page-wrap .home-slider'),
                menu = $('.push-menu-wrap'),
                categories = $('.main-categories'),
                $win = $(window);

            function setHeight() {
                var wHeight = $win.height();
                if ($win.width() >= 768) {
                    imgWrap.height(wHeight - menu.height() - categories.outerHeight(true));
                }
            }

            setHeight();

            $(window).bind('resize', function () {
                wHeight = $win.height();
                setHeight();
            });

            setTimeout(function () {
                imgWrap.addClass('fade');
            }, 300);

            // ### Isotope lists ###
            //var $productList = $('.product-list').imagesLoaded(function () {
            // init Isotope after all images have loaded
            //});
            var $productList = $('.product-list').isotope({
                itemSelector: '.list-item',
                percentPosition: false,
                transitionDuration: '0.65s',
                masonry: {
                    gutterWidth: 0,
                    columnWidth: 0
                },
                hiddenStyle: {
                    opacity: 0
                },
                visibleStyle: {
                    opacity: 1
                }
            });

            // store filter for each group
            var filters = {};

            $('body:not(.home.page) .product-categories a.cat-filter').on('click', function (event) {
                event.preventDefault();

                var $this = $(this),
                    $buttonGroup = $this.parents('.cat-list'),
                    filterGroup = $buttonGroup.attr('data-filter-group');

                if (filterGroup == 'main-cat') {
                    if ($this.hasClass('selected')) {
                        $('.sub-category', '.product-categories').hide();
                        $('.sub-category .selected').removeClass('selected');
                    } else {
                        $('.sub-category', '.product-categories').hide();
                        $('.sub-category .selected').removeClass('selected');
                        $('.parent-category-' + $this.data('category'), '.product-categories').show();
                    }
                }

                if ($this.hasClass('selected')) {
                    $('.selected', $buttonGroup).removeClass('selected');
                } else {
                    $('.selected', $buttonGroup).removeClass('selected');
                    $this.addClass('selected');
                }

                filters = {};

                $('.product-categories a.cat-filter.selected').each(function () {
                    var $this = $(this),
                        filterGroup = $this.parents('.cat-list').attr('data-filter-group');

                    filters[filterGroup] = $this.attr('data-filter');
                });

                window.location.hash = concatValues(filters);
            });

            $(window).on('hashchange', function () {
                $productList.isotope({filter: window.location.hash.substr(1)});
            });

            if (window.location.hash.length > 1) {
                $(window).trigger('hashchange');

                var prefix = ', a.cat-filter-',
                    main_category = window.location.hash.substr(2).split('.')[0],
                    elements = prefix.substr(1) + window.location.hash.substr(2).split('.').join(prefix);

                $('.parent-category-' + main_category).show();
                $(elements).addClass('selected');
            }

            // flatten object by concatting values
            function concatValues(obj) {
                var value = '';
                for (var prop in obj) {
                    value += obj[prop];
                }
                return value;
            }

            $('.news-list').imagesLoaded(function () {
                var $newsList = $('.news-list').isotope({
                    itemSelector: '.news-article',
                    percentPosition: true,
                    masonry: {
                        columnWidth: '.news-article'
                    },
                    animationOptions: {
                        duration: 300,
                        queue: true
                    }
                });

                // ### Accordion ###
                $('.news-article .trigger-link').click(function (event) {
                    event.preventDefault();
                    $('.news-article.opened').not($(this).parents('.news-article')).toggleClass('opened');
                    $(this).parents('.news-article').toggleClass('opened');
                    setTimeout(function () {
                        $newsList.isotope();
                    }, 600);
                });
            });

            $('.overlay, .modal .close').on('click', function (e) {
                e.preventDefault();

                $('.modal').addClass('fadeOutUp');
                $('.tint').addClass('fadeOut');
                setTimeout(function () {
                    $('.modal').removeClass('fadeInDownBig fadeOutUp');
                    $('.tint').removeClass('fadeOut fadeIn');
                    $('.overlay').hide();
                    $('body').removeClass('noscroll');
                    $('.modal .modal-content').hide();
                }, 1200);
            });

            $('.overlay .modal-content').click(function (e) {
                e.stopPropagation();
            });
        },

        sliders: function () {
            var terminate_hover = function (slider) {
                    $('.slick-hover', slider).removeClass('slick-hover');
                },
                slider_buttons = function (slider) {
                    $('.slick-prev', slider)
                        .on('mouseenter', function () {
                            $('.slick-current', slider).prev().addClass('slick-hover');
                        })
                        .on('mouseleave', function () {
                            terminate_hover();
                        })
                        .on('click', function () {
                            $(this).trigger('mouseleave').trigger('mouseenter');
                        });

                    $('.slick-next', slider)
                        .on('mouseenter', function () {
                            $('.slick-current', slider).next().addClass('slick-hover');
                        })
                        .on('mouseleave', function () {
                            terminate_hover();
                        })
                        .on('click', function () {
                            $(this).trigger('mouseleave').trigger('mouseenter');
                        });
                };

            if (!$('.product-images-slider').hasClass('single-image') && $('.product-images-slider .slick-slider').length) {
                $('.product-images-slider .slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: true,
                            speed: 800,
                            slidesToShow: 1,
                            centerMode: true,
                            variableWidth: true,
                            init: slider_buttons($slider)
                        })
                    );
                });
            }

            if (!$('.home-slider').hasClass('single-image') && $('.home-slider .slick-slider').length) {
                $('.home-slider .slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: true,
                            speed: 800,
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding: '0px',
                            // variableWidth: true,
                            autoplay: true,
                            autoplaySpeed: 5000,
                            init: slider_buttons($slider)
                        })
                    );
                });
            }

            if ($('.reference-images-slider .slick-slider').length) {
                $('.reference-images-slider .slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: true,
                            speed: 800,
                            slidesToShow: 1,
                            centerMode: true,
                            variableWidth: true,
                            init: slider_buttons($slider)
                        })
                    );
                });
            }

            if ('.product-graphs-slider .slick-slider'.length) {
                $('.product-graphs-slider .slick-slider').slick({
                    infinite: false,
                    speed: 800,
                    slidesToShow: 1,
                    centerMode: true,
                    variableWidth: true,
                    adaptiveHeight: false,
                    dots: true
                });
            }
        },

        _showOverlay: function (name) {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                close = $('.close', overlay),
                tint = $('.tint'),
                modal = $('.modal');

            body.addClass('noscroll');
            overlay.show();
            tint.addClass('fadeIn');
            overlay.attr('class', 'modal-overlay').addClass(name);
            $('.modal-content', overlay).show();
            $('#' + name).show();
            modal.addClass('fadeInDownBig');
        },

        _hideOverlay: function () {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                close = $('.close', overlay),
                tint = $('.tint'),
                modal = $('.modal');

            modal.addClass('fadeOutUp');
            tint.addClass('fadeOut');
            $('.modal-trigger.modal-active').removeClass('modal-active');

            setTimeout(function () {
                modal.removeClass('fadeInDownBig fadeOutUp');
                tint.removeClass('fadeOut fadeIn');
                overlay.hide().attr('class', 'modal-overlay');
                body.removeClass('noscroll');
                $('.modal .modal-content').hide();
            }, 1000);
        },

        popups: function () {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                tint = $('.tint'),
                close = $('.close', overlay),
                modal_triggers = $('.modal-trigger'),
                active_item = false;

            // Modal close button
            $('.close').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                App._hideOverlay();
            });

            // Attach popups event handlers
            modal_triggers.click(function (e) {
                e.preventDefault();

                var self = $(this),
                    overlay_id = self.data('modal'),
                    img = $('#' + overlay_id + ' .modal-img');

                modal_triggers.filter('.modal-active').removeClass('modal-active');
                self.addClass('modal-active');

                active_item = self;

                if (img.length != 0 && !img.hasClass('loaded')) {
                    img.attr('src', img.attr('data-src')).addClass('loaded');
                }

                App._showOverlay($(this).data('modal'));
            });

            overlay.click(function () {
                App._hideOverlay();
            });

            $('.modal-content', overlay).click(function (e) {
                e.stopPropagation();
            });

            $(window).on('keyup', function (event) {
                if (modal_triggers.filter('.modal-active').length) {
                    switch (event.keyCode) {
                        case 37: //left
                            prev_btn.trigger('click');
                            break;
                        case 39: //right
                            next_btn.trigger('click');
                            break;
                        case 27: //esc
                            App._hideOverlay();
                            break;
                    }
                }
            });
        },

        mobMenu: function (menuWrapper) {
            $this = menuWrapper;
            var mobMenu = $('.mob-menu', $this);

            /* prepend menu icon */
            $this.prepend('<p class="menu-button"><span></span></p>');

            /* toggle main menu nav */
            $('.menu-button', $this).on('click', function () {
                $(this).toggleClass('cross');
                menuWrapper.toggleClass('opened');
                $('body').toggleClass('opened');
            });

            $('.page-wrap').on('click', function () {
                if ($('body').hasClass('opened')) {
                    $('.menu-button', $this).removeClass('cross');
                    menuWrapper.removeClass('opened');
                    $('body').removeClass('opened');
                }
            });

            var $window = $(window);

            function checkWidth() {
                var wWidth = $window.width();
                if (wWidth > 1024) {
                    //console.log('suur');
                    //menuWrapper.show(300);
                } else {
                }
            }

            // Execute on load
            checkWidth();

            $(window).bind('resize', function () {
                wWidth = $(window).width();
                checkWidth();
            });
        }
    };

$(document).ready(function () {
    App.init();
    App.mobMenu($('.push-menu-wrap'));
});
